import moment from "moment"

// Utility functions
import { renderServerBase, renderServerKey, renderStorageKey } from "./functions"

// Server endpoints
const serverBase = renderServerBase()
const serverKey = renderServerKey()

// Local storage base key
const localStorageKey = renderStorageKey()

// Store an array of requests to process and an ID to keep track of the interval
let offlineRequests = []
let intervalID = null

// Called when the application is loaded and authenticated
export const checkOfflineRequests = async () => {
	// Check for any requests still be stored locally
	if (localStorage.getItem(`${localStorageKey}requests`)) {
		// Get the offline toggle from the local storage
		const offlineToggle = localStorage.getItem(`${localStorageKey}offline_toggle`) || false

		// Get the offline toggle as a boolean
		const isOfflineToggled = offlineToggle === "true" ? true : false

		// Has the user toggled the offline mode?
		if (isOfflineToggled) return

		// Parse them and set them into the array object
		offlineRequests = JSON.parse(localStorage.getItem("rbr_trackside_requests")) || []

		// Attempt to process any requests found
		if (offlineRequests.length > 0) {
			processOfflineRequests()
		}
	}
}

// Process the stack of offline requests
export const processOfflineRequests = async () => {
	// Start the interval if it's not already running
	if (!intervalID) {
		intervalID = setInterval(() => {
			// Get the offline toggle from the local storage
			const offlineToggle = localStorage.getItem("rbr_trackside_offline_toggle") || false

			// Get the offline toggle as a boolean
			const isOfflineToggled = offlineToggle === "true" ? true : false

			// Check if there's a network connection
			if (navigator.onLine && !isOfflineToggled) {
				// Clear the interval
				clearInterval(intervalID)
				intervalID = null

				// Process the requests in the queue
				while (offlineRequests.length > 0) {
					// Get the first element from the array
					const processRequest = offlineRequests.shift()

					// Set the updated object back into local storage
					localStorage.setItem("rbr_trackside_requests", JSON.stringify(offlineRequests))

					// And then process the request we lifted
					safeRequest(processRequest.method, processRequest.endpoint, processRequest.body)
				}
			}
		}, 2000)
	}
}

// Middleware function to attempt requests into the database, otherwise saves locally to retry
export const safeRequest = async (method = "GET", endpoint, body = {}, key) => {
	return new Promise((res, rej) => {
		// Get the offline toggle from the local storage
		const offlineToggle = localStorage.getItem("rbr_trackside_offline_toggle") || false

		// Get the offline toggle as a boolean
		const isOfflineToggled = offlineToggle === "true" ? true : false

		// Get the MSAL access token from the local storage
		const msalAccessToken = localStorage.getItem("RBR_Trackside_MSAL_Auth") || null

		// Check to see if there is a network connection
		if (navigator.onLine && !isOfflineToggled) {
			// Store some options for the request
			let requestOptions = {
				method,
				headers: {
					Authorization: `Bearer ${msalAccessToken}`,
					"Content-Type": "application/json",
					APIKey: serverKey,
				},
			}

			// If the method is not GET, attach any body there might be
			if (method !== "GET") {
				requestOptions.body = JSON.stringify(body)
			}

			// Attempt to make the request
			fetch(`${serverBase}${endpoint}`, requestOptions)
				.then((response) => {
					// Return the response as JSON
					return response.json()
				})
				.then((data) => {
					// Return the data back to the request
					res(data)
				})
				.catch((err) => {
					console.error(err)
				})
		} else {
			// If the client is not online, store the request locally
			const newRequest = { method, endpoint, body }

			// Push the new request onto the offline stack to be processed
			offlineRequests.push(newRequest)

			// Then set this back into the local storage on the client
			localStorage.setItem("rbr_trackside_requests", JSON.stringify(offlineRequests))

			// Then attempt to start the offline interval check
			processOfflineRequests()

			// Close the promise out
			res({ offline: true })
		}
	})
}

// Attempt to fetch from the server, or return from cache
export const safeDataFetch = async (method = "GET", endpoint, body = {}, key) => {
	return new Promise((res, rej) => {
		// Get the offline toggle from the local storage
		const offlineToggle = localStorage.getItem("rbr_trackside_offline_toggle") || false

		// Get the offline toggle as a boolean
		const isOfflineToggled = offlineToggle === "true" ? true : false

		// Get the MSAL access token from the local storage
		const msalAccessToken = localStorage.getItem("RBR_Trackside_MSAL_Auth") || null

		// Is the client online?
		if (navigator.onLine && !isOfflineToggled) {
			// Store some options for the request
			let requestOptions = {
				method,
				headers: {
					Authorization: `Bearer ${msalAccessToken}`,
					"Content-Type": "application/json",
					APIKey: serverKey,
				},
			}

			// If the method is not GET, attach any body there might be
			if (method !== "GET") {
				requestOptions.body = JSON.stringify(body)
			}

			console.log(`${serverBase}${endpoint}`)
			// Attempt to make the request
			fetch(`${serverBase}${endpoint}`, requestOptions)
				.then((response) => {
					// Return the response as JSON
					return response.json()
				})
				.then((data) => {
					// Write then JSON response into the local storage
					localStorage.setItem(`rbr_trackside_${key}`, JSON.stringify(data))

					// Return the data back to the request
					res(data)
				})
				.catch((err) => {
					console.log(err)
				})
		} else {
			// If they are not online, find the matching key in the local storage cache
			const localCache = JSON.parse(localStorage.getItem(`rbr_trackside_${key}`)) || {}

			// Return this instead
			res(localCache)
		}
	})
}

// Fetch the CSV export from the server
export const fetchCSVExport = async (method = "GET", endpoint, filename) => {
	return new Promise((res, rej) => {
		// Get the MSAL access token from the local storage
		const msalAccessToken = localStorage.getItem("RBR_Trackside_MSAL_Auth") || null

		// Store some options for the request
		let requestOptions = {
			method,
			headers: {
				Authorization: `Bearer ${msalAccessToken}`,
				APIKey: serverKey,
			},
		}

		// Attempt to make the request
		fetch(`${serverBase}${endpoint}`, requestOptions)
			.then((response) => {
				// Return the raw body of the response
				return response.text()
			})
			.then((data) => {
				// Setup a new link object with the resulting CSV as the target
				const url = window.URL.createObjectURL(new Blob([data]))
				const link = document.createElement("a")
				link.href = url
				link.setAttribute("download", `${filename}.csv`)
				document.body.appendChild(link)

				// Download the CSV file and then cleanup the link
				link.click()
				link.remove()

				// Return the data back to the request
				res(data)
			})
			.catch((err) => {
				console.log(err)
				res()
			})
	})
}

// Attempt to redeem a given pin locally before dispatching a request to do the same server side
export const redeemGuestPin = async (eventID, pin, issued_by) => {
	return new Promise((res, rej) => {
		// Pull the local copy of the event guest list
		const localGuestListCache = JSON.parse(localStorage.getItem(`rbr_trackside_GUEST_LIST_${eventID}`)) || []

		// If we have no locally stored guest lists
		if (!localGuestListCache || typeof localGuestListCache === "undefined" || localGuestListCache.length === 0) {
			// We'll assume there are no further actions that can be taken and the redemption is not successful
			res({ result: false, status: 404 })
		}

		// Find the matching guest record
		const matchingGuest = localGuestListCache.find((guest) => guest.pin === parseInt(pin))

		// Same check again here to see if a guest record was returned
		if (!matchingGuest || typeof matchingGuest === "undefined" || !matchingGuest.id) {
			// No guest could be found with the given pin
			res({ result: false, status: 404 })
		}

		// Have they already redeemed their gift?
		if (matchingGuest.gift_received) {
			res({ result: false, status: 400, details: matchingGuest })
		}

		// Find the index for this guest in our local cached copy
		const matchingGuestIndex = localGuestListCache.findIndex((guest) => guest.pin === parseInt(pin))

		// Write the redemption data into the guest record
		localGuestListCache[matchingGuestIndex] = {
			...matchingGuest,
			gift_received: true,
			gift_issued_by: issued_by,
			gift_received_date: moment().toISOString(),
		}

		// Write the updated guest list back into local storage
		localStorage.setItem(`rbr_trackside_GUEST_LIST_${eventID}`, JSON.stringify(localGuestListCache))

		// Then fire the request off into the server, ignoring the response
		safeRequest("POST", `/guests/redeem`, { pin, issued_by })

		// Return true along with the guest information to close the promise out
		res({ result: true, status: 200, ...matchingGuest })
	})
}

// Attempt to redeem a given pin locally before dispatching a request to do the same server side
export const redeemGuestPinWithType = async (eventID, pin, type, issued_by) => {
	return new Promise((res, rej) => {
		// Pull the local copy of the event guest list
		const localGuestListCache = JSON.parse(localStorage.getItem(`rbr_trackside_GUEST_LIST_${eventID}`)) || []

		// If we have no locally stored guest lists
		if (!localGuestListCache || typeof localGuestListCache === "undefined" || localGuestListCache.length === 0) {
			// We'll assume there are no further actions that can be taken and the redemption is not successful
			res({ result: false, status: 404 })
		}

		// Find the matching guest record
		const matchingGuest = localGuestListCache.find((guest) => guest.pin === parseInt(pin))

		// Same check again here to see if a guest record was returned
		if (!matchingGuest || typeof matchingGuest === "undefined" || !matchingGuest.id) {
			// No guest could be found with the given pin
			res({ result: false, status: 404 })
		}

		// Have they already redeemed their gift?
		if (matchingGuest.gift_received) {
			res({ result: false, status: 400, details: matchingGuest })
		}

		// Find the index for this guest in our local cached copy
		const matchingGuestIndex = localGuestListCache.findIndex((guest) => guest.pin === parseInt(pin))	

		// Write the redemption data into the guest record
		localGuestListCache[matchingGuestIndex] = {
			...matchingGuest,
			redemptions: {
				...matchingGuest.redemptions,
				[type]: moment().toISOString(),
			},
		}

		// Write the updated guest list back into local storage
		localStorage.setItem(`rbr_trackside_GUEST_LIST_${eventID}`, JSON.stringify(localGuestListCache))

		// Then fire the request off into the server, ignoring the response
		safeRequest("POST", `/guests/redeemType`, { pin, type, issued_by })

		// Return true along with the guest information to close the promise out
		res({ result: true, status: 200, ...matchingGuest })
	})
}
